//@ts-check
import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

export const MAX_SHORTCUT_INDEX = 999;

/**
 * @typedef {{
 * 	changeShortcut: (sourceId: string, label: string, shortcut: number) => void,
 * 	getShortcut: (shortcutIndex: number) => Shortcut | undefined,
 * 	getShortcutIndex: (sourceId: string, label: string) => number,
 * 	isShortcutAvailable: (shortcut: number, sourceId: string) => boolean,
 * 	resetShortcuts: () => void,
 * 	shortcuts: Shortcut[],
 * 	shortcutsConfigId: string | undefined,
 * 	setShortcutsConfigId: React.Dispatch<React.SetStateAction<string | undefined>>,
 * 	swapShortcuts: (firstSourceId: string, secondSourceId: string) => void,
 * 	setShortcuts: React.Dispatch<React.SetStateAction<Shortcut[]>>,
 * }} IItemShortcutContext
 */

const ItemShortcutContext = createContext(/** @type {IItemShortcutContext} */({}));

/**
 * @returns {IItemShortcutContext}
 */
export const useShortcut = () => {
	const itemShortcutContext = useContext(ItemShortcutContext);
	// type guard (removes undefined type)
	if (!itemShortcutContext) {
		throw new Error('useShortcut must be used within a ItemShortcutProvider');
	}
	return itemShortcutContext;
};

/**
 * @typedef {{
 *   sourceId: string,
 *   label: string,
 *   shortcut: number,
 * }} Shortcut
 */

/**
 * @param {React.PropsWithChildren} props
 * @returns {React.ReactNode}
 */
export const ItemShortcutProvider = ({ children }) => {
	const [shortcuts, setShortcuts] = useState(/** @type {Array<Shortcut>} */([]));
	const [shortcutsConfigId, setShortcutsConfigId] = useState(
		/** @type {string | undefined} */(undefined),
	);

	const findNextAvailableShortcut = useCallback(() => {
		for (let i = 1; i <= MAX_SHORTCUT_INDEX; i += 1) {
			if (!shortcuts.find((s) => s.shortcut === i)) {
				return i;
			}
		}
		return -1;
	}, [shortcuts]);

	const getShortcut = useCallback(
		/**
		 * @param {number} shortcutIndex
		 * @returns {Shortcut | undefined}
		 */
		(shortcutIndex) => shortcuts.find(
			(s) => s.shortcut === shortcutIndex,
		), [shortcuts],
	);

	const getShortcutIndex = useCallback(
		/**
		 * @param {string} sourceId
		 * @param {string} label
		 * @returns {number}
		 */
		(sourceId, label) => {
			if (!sourceId) throw new Error('SourceId is undefined');

			const source = shortcuts.find((s) => s.sourceId === sourceId);
			if (source) return source.shortcut;

			const shortcutIndex = findNextAvailableShortcut();
			if (shortcutIndex === -1) return shortcutIndex;

			setShortcuts((prev) => {
			//Double-check if shortcut was already assigned in previous updates
			//Since the setState is async, the shortcut could have been assigned in a previous update
				if (prev.find((s) => s.sourceId === sourceId)) {
					return prev;
				}

				if (prev.find((s) => s.shortcut === shortcutIndex)) {
					return prev;
				}

				return [...prev, { sourceId, label, shortcut: shortcutIndex }];
			});

			return shortcutIndex;
		}, [shortcuts, findNextAvailableShortcut],
	);

	const changeShortcut = useCallback(
		/**
		 * @param {string} sourceId
		 * @param {string} label
		 * @param {number} shortcut
		 */
		(sourceId, label, shortcut) => {
			if (shortcut < 1 || shortcut > MAX_SHORTCUT_INDEX || !shortcut) throw new Error(`Shortcut should be between 1 and ${MAX_SHORTCUT_INDEX}`);
			setShortcuts((prev) => ([
				...prev.filter((s) => s.sourceId !== sourceId),
				{ sourceId, shortcut, label },
			]));
		}, [],
	);

	const isShortcutAvailable = useCallback(
		/**
		 * @param {number} shortcut
		 * @param {string} sourceId
		 * @returns {boolean}
		 */
		(shortcut, sourceId) => {
			if (shortcut < 1 || shortcut > MAX_SHORTCUT_INDEX || !shortcut) throw new Error(`Shortcut should be between 1 and ${MAX_SHORTCUT_INDEX}`);
			return !shortcuts.find((s) => s.shortcut === shortcut && s.sourceId !== sourceId);
		}, [shortcuts],
	);

	const swapShortcuts = useCallback(
		/**
		 * @param {string} firstSourceId
		 * @param {string} secondSourceId
		 */
		(firstSourceId, secondSourceId) => {
			const firstSource = shortcuts.find((s) => s.sourceId === firstSourceId);
			const secondSource = shortcuts.find((s) => s.sourceId === secondSourceId);
			if (!firstSource || !secondSource) return;
			changeShortcut(firstSourceId, firstSource.label, secondSource.shortcut);
			changeShortcut(secondSourceId, secondSource.label, firstSource.shortcut);
		}, [shortcuts, changeShortcut],
	);

	const resetShortcuts = useCallback(() => {
		setShortcuts([]);
	}, []);

	const value = useMemo(() => ({
		changeShortcut,
		getShortcut,
		getShortcutIndex,
		isShortcutAvailable,
		resetShortcuts,
		shortcuts,
		shortcutsConfigId,
		setShortcutsConfigId,
		swapShortcuts,
		setShortcuts,
	}), [
		changeShortcut,
		getShortcut,
		getShortcutIndex,
		isShortcutAvailable,
		resetShortcuts,
		shortcuts,
		shortcutsConfigId,
		setShortcutsConfigId,
		swapShortcuts,
		setShortcuts,
	]);

	return (
		<ItemShortcutContext.Provider value={value}>
			{children}
		</ItemShortcutContext.Provider>
	);
};

ItemShortcutProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
